import { useContext } from 'react';
import { BasketDispatchContext } from '../basket.provider';
import useGetSessionId from '../../user/hooks/use-get-session-id.hook';
import orderMemberQuoteSelect from '../../../../services/content-api/endpoints/order/member/quote/select';
import updateBasketQuote from '../actions/update-basket-quote';
import { QuoteResponseType } from '../../../../models/quote-response-type';
import invalidateBasket from '../actions/invalidate-basket';

const useSelectQuote: () => (
  reference: string
) => Promise<QuoteResponseType> = () => {
  const basketDispatch = useContext(BasketDispatchContext);
  const getSessionId = useGetSessionId();

  return async (reference) => {
    const response = await orderMemberQuoteSelect(
      reference,
      await getSessionId()
    );

    if (!response.errorCode) {
      basketDispatch(invalidateBasket());
      basketDispatch(
        updateBasketQuote({
          id: response.quote.quoteNumber,
          deliveryAddress: response.quote.deliveryAddress?.deliveryName,
          deliveryCarriage: response.quote.carriageCode,
          skus: response.quote.quoteLines.map((line) => line.productCode),
        })
      );
    }

    return response;
  };
};

export default useSelectQuote;
