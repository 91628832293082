import React, { FC } from 'react';
import { useRouter } from 'next/router';
import AlertModal from '../alert-modal/alert-modal';
import { LinkType } from '../../../models/link-type';
import interpolateContent from '../../../helpers/interpolate-content';

interface Props {
  heading: string;
  helpLink: LinkType;
  message: string;
  reference: string;
  submitLink: LinkType;
}

const QuoteConversionExpiredModal: FC<Props> = ({
  heading,
  helpLink,
  message,
  reference,
  submitLink,
}: Props) => {
  const router = useRouter();

  return (
    <AlertModal
      heading={heading}
      message={interpolateContent(message, reference)}
      submitMessage={submitLink.name}
      cancelMessage={helpLink.name}
      onCancel={() => router.push(helpLink.url)}
      onSubmit={() => router.push(submitLink.url)}
    />
  );
};

export default QuoteConversionExpiredModal;
