enum QuoteResponseErrorCodeEnum {
  Success = 0,
  QuoteNumberNotPassed = 20,
  AccountNumberNotPassed = 21,
  QuoteAlreadySelected = 22,
  TooManyQuotesSelected = 23,
  NotFound = 24,
  OnHold = 25,
  Completed = 26,
  Expired = 27,
  BDT = 28,
  IncorrectCustomer = 29,
}

export default QuoteResponseErrorCodeEnum;
