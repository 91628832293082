import { Form, Formik } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import QuoteResponseErrorCodeEnum from '../../../models/quote-response-error-code-enum';
import { RetrieveQuoteType } from '../../../models/retrieve-quote-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import FormText from '../../atoms/form-text/form-text';
import QuoteConversionAddProductsModal from '../../molecules/quote-conversion-add-products-modal/quote-conversion-add-products-modal';
import QuoteConversionAlreadyUsedModal from '../../molecules/quote-conversion-already-used-modal/quote-conversion-already-used-modal';
import QuoteConversionClearBasketModal from '../../molecules/quote-conversion-clear-basket-modal/quote-conversion-clear-basket-modal';
import QuoteConversionExpiredModal from '../../molecules/quote-conversion-expired-modal/quote-conversion-expired-modal';
import QuoteConversionNotFoundModal from '../../molecules/quote-conversion-not-found-modal/quote-conversion-not-found-modal';
import RichText from '../../molecules/rich-text/rich-text';
import invalidateBasket from '../../providers/basket/actions/invalidate-basket';
import {
  BasketStateContext,
  BasketDispatchContext,
} from '../../providers/basket/basket.provider';
import useDeselectQuote from '../../providers/basket/hooks/use-deselect-quote';
import useSelectQuote from '../../providers/basket/hooks/use-select-quote';
import open from '../../providers/overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';

const RetrieveQuote: FC<RetrieveQuoteType> = ({
  addProductErrorHeading,
  addProductErrorDescription,
  addProductErrorBody,
  insufficientClearanceStockError,
  addProductsAddButtonLink,
  addProductsCancelLabel,
  addProductsDescription,
  addProductsHeading,
  clearBasketDescription,
  clearBasketHeading,
  clearBasketHelpText,
  clearBasketKeepButtonLabel,
  clearBasketOkButtonLink,
  clearQuoteAccept,
  clearQuoteCancel,
  clearQuoteDescription,
  clearQuoteHeading,
  clearQuoteHelp,
  description,
  quoteAlreadyUsedBasketLink,
  quoteAlreadyUsedDescription,
  quoteAlreadyUsedGetHelpLink,
  quoteAlreadyUsedHeading,
  quoteExpiredContactHelpLink,
  quoteExpiredDescription,
  quoteExpiredGoToBasketLink,
  quoteExpiredHeading,
  quoteNotFoundContactHelpLink,
  quoteNotFounDescription,
  quoteNotFoundHeading,
  quoteNotFoundReenterNumberLabel,
  referenceNumberLabel,
  referenceNumberPlaceholder,
  title,
}) => {
  const { basket } = useContext(BasketStateContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const selectQuote = useSelectQuote();
  const deselectQuote = useDeselectQuote();
  const [isSelectingQuote, setIsSelectingQuote] = useState<boolean>(false);

  useEffect(() => {
    basketDispatch(invalidateBasket());
  }, []);

  const onSubmit = async (reference: string) => {
    if (!basket) {
      return;
    }

    setIsSelectingQuote(true);

    await deselectQuote();

    const addProductErrorLabels = {
      addProductErrorHeading,
      addProductErrorDescription,
      addProductErrorBody,
      insufficientClearanceStockError,
    };

    const clearQuoteLabels = {
      clearQuoteAccept,
      clearQuoteCancel,
      clearQuoteDescription,
      clearQuoteHeading,
      clearQuoteHelp,
    };

    try {
      const response = await selectQuote(reference);

      setIsSelectingQuote(false);

      if (!response.errorCode && basket.lineItems.length > 0) {
        overlayDispatch(
          open(
            <QuoteConversionClearBasketModal
              addProductErrorLabels={addProductErrorLabels}
              cancelMessage={clearBasketKeepButtonLabel}
              clearQuoteLabels={clearQuoteLabels}
              heading={clearBasketHeading}
              headingHelp={clearBasketHelpText}
              message={clearBasketDescription}
              quote={response.quote}
              reference={reference}
              submitLink={clearBasketOkButtonLink}
            />
          )
        );

        return;
      }

      if (!response.errorCode && basket.lineItems.length === 0) {
        overlayDispatch(
          open(
            <QuoteConversionAddProductsModal
              addProductErrorLabels={addProductErrorLabels}
              cancelMessage={addProductsCancelLabel}
              clearQuoteLabels={clearQuoteLabels}
              heading={addProductsHeading}
              message={addProductsDescription}
              quote={response.quote}
              reference={reference}
              submitLink={addProductsAddButtonLink}
            />
          )
        );

        return;
      }

      if (response.errorCode === QuoteResponseErrorCodeEnum.Completed) {
        overlayDispatch(
          open(
            <QuoteConversionAlreadyUsedModal
              heading={quoteAlreadyUsedHeading}
              helpLink={quoteAlreadyUsedGetHelpLink}
              message={quoteAlreadyUsedDescription}
              reference={reference}
              submitLink={quoteAlreadyUsedBasketLink}
            />
          )
        );

        return;
      }

      if (response.errorCode === QuoteResponseErrorCodeEnum.Expired) {
        overlayDispatch(
          open(
            <QuoteConversionExpiredModal
              heading={quoteExpiredHeading}
              helpLink={quoteExpiredContactHelpLink}
              message={quoteExpiredDescription}
              reference={reference}
              submitLink={quoteExpiredGoToBasketLink}
            />
          )
        );

        return;
      }

      if (
        response.errorCode === QuoteResponseErrorCodeEnum.NotFound ||
        response.errorCode === QuoteResponseErrorCodeEnum.IncorrectCustomer
      ) {
        overlayDispatch(
          open(
            <QuoteConversionNotFoundModal
              heading={quoteNotFoundHeading}
              helpLink={quoteNotFoundContactHelpLink}
              message={quoteNotFounDescription}
              submitMessage={quoteNotFoundReenterNumberLabel}
            />
          )
        );

        return;
      }
    } catch (error) {
      setIsSelectingQuote(false);
      overlayDispatch(
        open(
          <QuoteConversionNotFoundModal
            heading={quoteNotFoundHeading}
            helpLink={quoteNotFoundContactHelpLink}
            message={quoteNotFounDescription}
            submitMessage={quoteNotFoundReenterNumberLabel}
          />
        )
      );
    }
  };

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing">
      <div className="bg-light-blue rounded-lg p-6 lg:p-8 grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 items-center">
        <div className="space-y-2">
          <h4 className="page-heading-four">{title}</h4>

          <RichText className="text-grey-600">{description}</RichText>
        </div>

        <Formik
          initialValues={{ reference: '' }}
          onSubmit={({ reference }) => onSubmit(reference)}
          validationSchema={yup.object({
            reference: yup.string().required('Reference number is required'),
          })}
        >
          {({ isValid }) => (
            <Form className="flex flex-col lg:flex-row items-center lg:items-start space-x-0 lg:space-x-8 w-full">
              <div className="w-full">
                <FormText
                  backgroundColor="white"
                  disabled={isSelectingQuote}
                  label={referenceNumberLabel}
                  max={2147483647}
                  min={1}
                  name="reference"
                  placeholder={referenceNumberPlaceholder}
                  required
                  step={1}
                  type="number"
                />
              </div>

              <div className="flex justify-center mt-8 lg:mt-7 w-full lg:w-auto">
                <ButtonPrimary
                  buttonType="submit"
                  disabled={!isValid}
                  name="Retrieve"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RetrieveQuote;
