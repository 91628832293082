import React, { FC } from 'react';
import { useRouter } from 'next/router';
import AlertModal from '../alert-modal/alert-modal';
import { LinkType } from '../../../models/link-type';
import interpolateContent from '../../../helpers/interpolate-content';
import { KeaQuoteType } from '../../../models/kea-quote-type';
import useClearBasket from '../../providers/basket/hooks/use-clear-basket';
import useAddMultipleToBasket from '../../providers/basket/hooks/use-add-multiple-to-basket';
import { BasketClearQuoteLabelsType } from '../../../models/basket-clear-quote-labels-type';
import { BasketAddProductErrorLabelsType } from '../../../models/basket-add-product-error-labels-type';

interface Props {
  addProductErrorLabels: BasketAddProductErrorLabelsType;
  cancelMessage: string;
  clearQuoteLabels: BasketClearQuoteLabelsType;
  heading: string;
  headingHelp: string;
  message: string;
  quote: KeaQuoteType;
  reference: string;
  submitLink: LinkType;
}

const QuoteConversionClearBasketModal: FC<Props> = ({
  addProductErrorLabels,
  cancelMessage,
  clearQuoteLabels,
  heading,
  headingHelp,
  message,
  quote,
  reference,
  submitLink,
}: Props) => {
  const router = useRouter();
  const clearBasket = useClearBasket({} as BasketClearQuoteLabelsType, {
    ignoreClearQuoteAlert: true,
  });
  const addMultipleToBasket = useAddMultipleToBasket(
    addProductErrorLabels,
    clearQuoteLabels
  );

  return (
    <AlertModal
      heading={heading}
      headingHelp={headingHelp}
      message={interpolateContent(message, reference)}
      submitMessage={submitLink.name}
      cancelMessage={cancelMessage}
      onSubmit={async () => {
        await clearBasket();

        const { lineItemIssues } = await addMultipleToBasket(
          quote.quoteLines.map(
            ({
              productCode: variantSku,
              quantityOutstandingOnQuote: quantity,
            }) => ({
              variantSku,
              quantity,
            })
          )
        );

        if (lineItemIssues?.length) {
          // TODO FLOFEB-26: Handle this with another modal
        }

        router.push(submitLink.url);
      }}
    />
  );
};

export default QuoteConversionClearBasketModal;
