import CultureCodeEnum from '../../../../../../models/culture-code-enum';
import client from '../../../../client';
import { QuoteResponseType } from '../../../../../../models/quote-response-type';

const orderMemberQuoteSelect = async (
  quoteNumber: string,
  sessionId: string
): Promise<QuoteResponseType> => {
  const { data } = await client.post<QuoteResponseType>(
    '/order/member/quote/select',
    {
      quoteNumber,
      sessionId,
      provider: 0,
      accountNumber: 0,
    },
    {
      params: {
        cultureCode: CultureCodeEnum.EnGb,
        domain: process.env.NEXT_PUBLIC_DOMAIN,
      },
    }
  );

  return data;
};

export default orderMemberQuoteSelect;
