import Action from '../../../../types/action';
import BasketActions from '.';
import { QuoteState } from '../basket.state';

const updateBasketQuote = (quote: QuoteState): Action<BasketActions> => {
  return {
    type: BasketActions.UpdateBasketQuote,
    payload: quote,
  };
};

export default updateBasketQuote;
