import React, { FC } from 'react';
import { useRouter } from 'next/router';
import AlertModal from '../alert-modal/alert-modal';
import { LinkType } from '../../../models/link-type';
import interpolateContent from '../../../helpers/interpolate-content';
import { KeaQuoteType } from '../../../models/kea-quote-type';
import useAddMultipleToBasket from '../../providers/basket/hooks/use-add-multiple-to-basket';
import { BasketClearQuoteLabelsType } from '../../../models/basket-clear-quote-labels-type';
import { BasketAddProductErrorLabelsType } from '../../../models/basket-add-product-error-labels-type';

interface Props {
  addProductErrorLabels: BasketAddProductErrorLabelsType;
  cancelMessage: string;
  clearQuoteLabels: BasketClearQuoteLabelsType;
  heading: string;
  message: string;
  quote: KeaQuoteType;
  reference: string;
  submitLink: LinkType;
}

const QuoteConversionAddProductsModal: FC<Props> = ({
  addProductErrorLabels,
  cancelMessage,
  clearQuoteLabels,
  heading,
  message,
  quote,
  reference,
  submitLink,
}: Props) => {
  const router = useRouter();
  const addMultipleToBasket = useAddMultipleToBasket(
    addProductErrorLabels,
    clearQuoteLabels,
    { ignoreClearQuoteAlert: true }
  );

  return (
    <AlertModal
      cancelMessage={cancelMessage}
      heading={heading}
      message={interpolateContent(message, reference)}
      size="medium"
      submitMessage={submitLink.name}
      onSubmit={async () => {
        await addMultipleToBasket(
          quote.quoteLines.map(
            ({
              productCode: variantSku,
              quantityOutstandingOnQuote: quantity,
            }) => ({
              variantSku,
              quantity,
            })
          )
        );

        router.push(submitLink.url);
      }}
    />
  );
};

export default QuoteConversionAddProductsModal;
