import React, { FC } from 'react';
import { useRouter } from 'next/router';
import AlertModal from '../alert-modal/alert-modal';
import { LinkType } from '../../../models/link-type';

interface Props {
  heading: string;
  helpLink: LinkType;
  message: string;
  submitMessage: string;
}

const QuoteConversionNotFoundModal: FC<Props> = ({
  heading,
  helpLink,
  message,
  submitMessage,
}: Props) => {
  const router = useRouter();

  return (
    <AlertModal
      heading={heading}
      message={message}
      submitMessage={submitMessage}
      cancelMessage={helpLink.name}
      onCancel={() => router.push(helpLink.url)}
    />
  );
};

export default QuoteConversionNotFoundModal;
